body {
  margin: 0;
  /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif; */
  font-family: 'Rubik', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.rotate-45 {
  transform: rotate(45deg);
  transition: transform 0.3s ease-in-out;
}

.modal {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.modal-content {
  width: 600px;
}


.overlay {
  background: #00386d;
  opacity: 0.2;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}

/* Estilo para la barra de desplazamiento */
::-webkit-scrollbar {
  width: 10px;
  /* Ancho de la barra de desplazamiento */
}

/* Estilo para el riel de la barra de desplazamiento */
::-webkit-scrollbar-track {
  background: #f1f1f1;
  /* Color de fondo del riel */
}

/* Estilo para el pulgar de la barra de desplazamiento */
::-webkit-scrollbar-thumb {
  background: #888;
  /* Color del pulgar */
  border-radius: 5px;
  /* Radio de borde del pulgar */
}

/* Estilo para el pulgar de la barra de desplazamiento al pasar el mouse */
::-webkit-scrollbar-thumb:hover {
  background: #555;
  /* Color del pulgar al pasar el mouse */
}


/* Estilos base */
.container {
  width: 90%;
  margin: 0 auto;
}

/* Media queries para diferentes tamaños de pantalla */
@media screen and (min-width: 768px) {
  .container {
    width: 80%;
  }
}

@media screen and (min-width: 1024px) {
  .container {
    width: 70%;
  }
}

@tailwind base;
@tailwind components;
@tailwind utilities;